$.fn.activeNav = function(options){
    var defaults = {
      obj: $(this),
      dataNavActive: "nav-active",
      dataNav: "nav",
      objToActive: ".activeNav"
    }
    var settings = $.extend(defaults, options);
    
    let thisPage = settings.obj.data(settings.dataNavActive);

    $(settings.objToActive).children("ul").children("li").children("a").removeClass("active");
    $(settings.objToActive).children("ul").children("li").children('[data-'+settings.dataNav+'="'+thisPage+'"]').addClass("active");

}