$.ajaxSetup({ cache: true });
$.getScript('https://connect.facebook.net/en_US/sdk.js', function(){
    FB.init({
      appId: "526742574046446",
      version: "v3.2",
      xfbml: 1 
    });
});

// setting types: share, send messenger

$.fn.callSocial = function(options){
    var defaults = {
      obj: $(this),
      type: $(this).data("callsocial-type"),
      href: $(this).data("callsocial-href"),
      link: $(this).data("callsocial-link"),
      callback: function(){}
    }
    var settings = $.extend(defaults, options);

    settings.obj.on("click", function(){
      
      FB.ui({
        method: settings.type,
        href: settings.href,
        link: settings.link,
        mobile_iframe: true
      },
      settings.callback());
    })
}
