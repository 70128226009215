$.fn.scrollLink = function(options){
    var defaults = {
      obj: $(this),
      offset: 0
    }
    var settings = $.extend(defaults, options);
  
    settings.obj.on("click", function(e){
        e.preventDefault(); 

        let hash = settings.obj.attr("href");
        let offsetTop = $(hash).offset().top - settings.offset;

        $("html, body").animate({
            scrollTop: offsetTop + "px"
        }, 200);

    })
  }
