import initIcons from "./_svg-icons.js";
import "./_social";

$.fn.loadAjax = function(options){
  var defaults = {
    obj: $(this),
    language: {
      'loading': 'Content is loading...',
      'error': 'Error occured!',
      'noresult': 'There is no result!'
    }
  }
  var settings = $.extend(defaults, options);

  const loadAjax = {
    getCore: () => settings.obj.data('loadajax-core'),
    getPage: () => {
      let laCore = loadAjax.getCore();
      let laPage = $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-page');
      return laPage;
    },
    getCate: () => {
      let laCore = loadAjax.getCore();
      let laCate = $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-cate');
      return laCate;
    },
    getUrl: () => settings.obj.data('loadajax-url'),
    getObj: () => settings.obj.data('loadajax-obj'),
    setPage: (newPage) => {
      let laCore = loadAjax.getCore();
      $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-page', newPage);
      return true;
    },
    setCate: (newCate) => {
      console.log(newCate);
      let laCore = loadAjax.getCore();
      $(`[data-loadajax-core="${laCore}"]`).attr('data-loadajax-cate', newCate);
      return true;
    },
    isNull: (result) => (result.replace(/(\s)+/g,"").length < 20),
    clearObj: () => {
      let laObj = loadAjax.getObj();
      $(laObj).empty();
      return true;
    },
    displayLoadingBlock: (text = '', theme = '') => {
      let data_text = (text.length > 0) ? text : settings.language.error;
      let default_theme = `<div class="error__single loadAjaxWait col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <span class="meta loading--block">
          <span class="container">
            <span class="meta__text">${data_text}</span>
          </span>
        </span>
      </div>`;
      let data_theme = (theme.length > 0) ? theme : default_theme;
      let laObj = loadAjax.getObj();
      $(laObj).append(data_theme);
    },
    clearLoadingBlock: function() {
      let laObj = loadAjax.getObj();
      $(laObj+' .loadAjaxWait').remove();
      return true;
    },
    handle: {
      loadBtn: () => {
        let laCore = loadAjax.getCore();
        console.log(`data-loadajax="${laCore}"`);
        $(`[data-loadajax="${laCore}"]`).on('click', function(){

          let $clickedObj = $(this);
          // Set Active Tab

          $(this).parent().siblings().removeClass('active');
          $(this).parent().addClass('active');
          
          // Cleanup & Display loading block

          let laType = $(this).data('loadajax-type');

          switch (laType) {
            case 'replace': 
              loadAjax.clearObj();
              loadAjax.displayLoadingBlock(settings.language.loading);
              break;
            case 'append': 
              loadAjax.displayLoadingBlock(settings.language.loading);
              break;
          }

          // Complete Ajax URL

          let configUrl = loadAjax.getUrl();

          // -- Check Cate

          let curCate = loadAjax.getCate();
          let newCate = $(this).data('loadajax-cate');

          if ((newCate !== undefined) && (newCate != curCate)) {
            loadAjax.setCate(newCate);
          }

          configUrl = configUrl.replace(':cate:', loadAjax.getCate());

          // -- Check Page

          let curPage = loadAjax.getPage();
          let newPage = $(this).data('loadajax-page');

          if ((newPage !== undefined) && (newPage != curPage)) {
            loadAjax.setPage(newPage);
          }

          // -- Check Load Type

          let loadType = $(this).data('loadajax-type');

          if ((loadType == 'append') || (loadType == 'next')) {
            newPage = parseInt(loadAjax.getPage()) + 1;
          }

          if (loadType == 'prev') {
            newPage = parseInt(loadAjax.getPage()) - 1;
          }

          loadAjax.setPage(newPage);

          configUrl = configUrl.replace(':page:', loadAjax.getPage());

          // Retrieve Data

          console.log(configUrl);

          $.ajax({
            url: configUrl,
            dataType: 'json',
            beforeSend: function(){
            },
            error: function(){
              loadAjax.clearObj();
              loadAjax.displayLoadingBlock();
            },
            success: function(result){
              if (loadAjax.isNull(result)) {
                switch (loadType) {
                  case 'replace': 
                    loadAjax.clearLoadingBlock();
                    loadAjax.displayLoadingBlock(settings.language.noresult);
                    break;
                  case 'append':
                    loadAjax.clearLoadingBlock();
                    loadAjax.displayLoadingBlock(settings.language.noresult);

                    $clickedObj.css({
                      'opacity': '0',
                      'pointer-events': 'none'
                    });

                    setTimeout(function(){
                      loadAjax.clearLoadingBlock();
                    }, 2000);

                    break;
                }
              } else {
                $('[data-loadajax]').css({
                  'opacity': '1',
                  'pointer-events': 'all'
                });
                loadAjax.clearLoadingBlock();

                let laObj = loadAjax.getObj();
                $(laObj).append(result);

                initIcons();
    
                $("[data-callsocial]").each(function(){
                    $(this).callSocial();
                });

              }
            }
          })
        });
      }
    },
    execute: function(){
      loadAjax.handle.loadBtn();
    }
  }

  loadAjax.execute();
}