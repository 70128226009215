import * as Cookies from 'js-cookie';

$.fn.login = function(options){
    var defaults = {
        obj: $(this),
        el: {
            grecaptcha: '#gReCaptcha',
            gameContainer: '#gameCanvas',
            username: '#userName',
            btnSignout: '#signOut',
            btnSignin: '#signIn',
            btnSignup: '#signUp',
            btnPlay: '.play-now',
            btnSigninZingID: '#btnSignin',
            btnSigninFacebook: '#btnSFacebook',
            btnSigninGoogle: '#btnSGoogle',
            btnSignupZingID: '#btnSignup',
            inpUsername: '#loginUsername',
            inpPassword: '#loginPassword',
            regUsername: '#regUsername',
            regPassword: '#regPassword',
            regRePassword: '#regRePassword',
            area: '#authArea',
            page: '#authPage',
            btnForgot: '#forgotPass',
            popup: '#popupLogin'
        },
        data: {
            site: '//',
            login: '/login-zp/home-login.html', 
            register: '/register-zp/home-login.html',
            grecaptchaKey: '6LfDrLsUAAAAALSewo3hFNU8T9nGYFrwhjafVPLu',
            facebookApp: '526742574046446',
            googleApp: '578063972293-4l5n2tt9qk72qb0ge9d9bao72k7qomcg.apps.googleusercontent.com',
            isSignnedIn: false,
            gameLink: '//'
        },
        signinFail: {
            toAddClass: {
                el: '#formLogin .form-control--error-full',
                className: 'form-control--error'
            },
            toAddText: {
                el: '#formLogin .form-control--error-full > .error-full',
            }
        },
        signupFail: {
            toAddClass: {
                el: '#formRegister .form-control--error-full',
                className: 'form-control--error'
            },
            toAddText: {
                el: '#formRegister .form-control--error-full > .error-full',
            }
        },
        diction: {
            wrongAuth: 'Tài khoản hoặc mật khẩu sai!',
            notAvailUsername: 'Tài khoản đã tồn tại!',
            notMatchPasswords: 'Mật khẩu nhập lại không khớp',
            wrongReg: 'Đăng ký không thành công'
        },
        dictionDefault: {
            error_4: 'Invalid password',
            error_5: 'Account existed',
            error_6: 'Invalid account',
            error_7: 'Invalid gameId',
            error_8: 'Invalid MAC',
            error_10: 'Invalid username',
            error_11: 'Invalid password',
            error_12: 'Username is already existed',
            error_13: 'Username does not exist',
            error_14: 'Valid session ID',
            error_15: 'Invalid session ID',
            error_16: 'ZingPlay ID does not exist',
            error_17: 'Invalid device ID',
            error_18: 'Invalid partner ID',
            error_20: 'Username contains forbidden word(s)',
            error_30: 'Username and password do not match',
            error_101: 'Invalid param: username, password, gameId',
            error_102: 'Invaild mac',
            error_121: 'Invalid gameId'
        },
    }
    var settings = $.extend(defaults, options);
    
    const login = {
        isLogged: function(response) {
            return (response.code == 1 && response.data.username != undefined)
        },
        isPlayingPage: function(){
            return ($(settings.el.gameContainer).length > 0);
        },
        activePopup: function(){
            if ($(settings.el.popup).length > 0) {
                $(settings.el.popup).addClass("active");
            } else {
                console.error('\''+ settings.el.popup +'\' is not defined!' );
            }
        },
        closePopup: function(){
            if ($(settings.el.popup).length > 0) {
                $(settings.el.popup).removeClass("active");
            } else {
                console.error('\''+ settings.el.popup +'\' is not defined!' );
            }
        },
        switchWelcome: function(username = ""){
            if ($(settings.el.username).length * 
                $(settings.el.btnSignout).length * 
                $(settings.el.btnSignin).length * 
                $(settings.el.btnSignup).length) {
                    if (username != undefined && username != "") {
                        $(settings.el.username).children(".username").html(username);
                        $(settings.el.username).show();
                        $(settings.el.btnSignout).show();
                        $(settings.el.btnSignin).hide();
                        $(settings.el.btnSignup).hide();
                    } else {
                        $(settings.el.username).hide();
                        $(settings.el.btnSignout).hide();
                        $(settings.el.btnSignin).show();
                        $(settings.el.btnSignup).show();
                    }
            } else {
                console.error('\''+ settings.el.username +'\',\''+ settings.el.btnSignout +'\', \''+ settings.el.btnSignin +'\' or \''+ settings.el.btnSignup +'\'  is not defined!' );
            }
        },
        redirectTo: function(url){
            window.location.href = url;
            // console.log('redirected to ' + url);
        },
        showError: function(mode = 'signin', texterror = settings.diction.wrongAuth){
            switch (mode) {
                case 'signin': 
                    $(settings.signinFail.toAddClass.el).addClass(settings.signinFail.toAddClass.className);
                    $(settings.signinFail.toAddText.el).html(texterror);
                    break; 
                case 'signup': 
                    $(settings.signupFail.toAddClass.el).addClass(settings.signupFail.toAddClass.className);
                    $(settings.signupFail.toAddText.el).html(texterror);
                    break;
            }
        },
        hideError: function(){
            $(settings.signinFail.toAddText.el).removeClass(settings.signinFail.toAddText.className);
            $(settings.signinFail.toAddText.el).html('');
            $(settings.signupFail.toAddText.el).removeClass(settings.signupFail.toAddText.className);
            $(settings.signupFail.toAddText.el).html('');
        },
        cleanUpCookies: function(){
            Cookies.remove('zp_username');
            Cookies.remove('zp_sessionKey'); 
            Cookies.remove('zp_accessToken'); 
            Cookies.remove('resp_username'); 
            return true;
        },
        renewCaptcha: function(callback = function(){}){
            grecaptcha.ready(function() {
                grecaptcha.execute(settings.data.grecaptchaKey, {action: 'homepage'}).then(function(token) {
                    $(settings.el.grecaptcha).val(token);
                    callback();
                });
            });
        },
        loadJSGame: function(){
            let scriptJS; 

            if ($("#jsLink").length > 0) {
                scriptJS = $("#jsLink").val();
            }

            // console.log(scriptJS);

            $("body").append(`<script cocos="" src="${scriptJS}"></script>`);

            // $.getScript(scriptJS);

        },
        checkStatus: function(){
            // login.renewCaptcha();

            let cookieUsername = Cookies.get('zp_username');
            let cookieAccessToken = Cookies.get('zp_accessToken');
            let cookiePlatform = Cookies.get('zp_platform');
            let cookieLanguage = Cookies.get('language');
            let cookieSessionKeyName = Cookies.get('sessionKeyNameList');
            let cookieDisplayUsername = Cookies.get('resp_username');

            // console.log(cookieUsername, cookiePlatform, cookieAccessToken, cookieSessionKey);

            // console.log(login.isPlayingPage())

            switch (login.isPlayingPage()) {
                case true: 

                    $(settings.el.page).val('game');
                    settings.el.area = "#authAreaForce";
                    
                    // game page

                    let gameCode = $("#gameCode").val();
                    let sessionKeyName;

                    
                    if (cookieSessionKeyName != undefined) {
                        sessionKeyName = JSON.parse(cookieSessionKeyName);
                    } else {
                        sessionKeyName = [];
                    }

                    // gameLoginId

                    let gameLoginId = '';

                    if ($("#gameLoginId").length > 0) {
                        gameLoginId = $("#gameLoginId").val();
                    }

                    // gameId

                    let gameId = '';

                    if ($("#gameId").length > 0) {
                        gameId = $("#gameId").val();
                    }

                    // let currentSessionKeyName = 'zp_sessionKey-' + gameCode;
                    let currentSessionKeyName = gameCode;
                    sessionKeyName.push(currentSessionKeyName);
                    Cookies.set('sessionKeyNameList', sessionKeyName);

                    let cookieSessionKey = Cookies.get(currentSessionKeyName);

                        //(cookieUsername != undefined) &&

                    if ((cookieSessionKey != undefined)) {
                        // already logged
                        login.renewCaptcha(function(){
                            $.post(settings.data.site + settings.data.login, {
                                'data' : {
                                    'area' : $(settings.el.area).val(),
                                    'page' : 'game',
                                    'gameCode': gameCode,
                                    'gameId': gameId,
                                    'gameLoginId': gameLoginId,
                                    'accessToken': cookieAccessToken,
                                    'platform' : cookiePlatform,
                                },
                                'g-recaptcha-response': $(settings.el.grecaptcha).val()
                            }, function(response){
                                response = JSON.parse(response);
                                if (login.isLogged(response)) {
                                    settings.data.isSignnedIn = true;
                                    login.loadJSGame();
                                    return true;
                                } else {
                                    settings.data.isSignnedIn = false;

                                    let textError = 'Unknow error!';
                                    if (settings.dictionDefault['error_' + response.code] != undefined) {
                                        textError = settings.dictionDefault['error_' + response.code];
                                    } else {
                                        textError = 'Error: ' + response.code;
                                    }
                                    login.showError('signin', textError);

                                    login.cleanUpCookies();
                                    login.activePopup();
                                    return false;
                                }
                            });
                        });
                        // settings.data.isSignnedIn = true;
                        // login.switchWelcome(cookieDisplayUsername);
                        // login.loadJSGame();
                        // return true;
                    } else if ((cookieAccessToken != undefined)) {
                        //login by accesstoken

                        // if ((cookieLanguage == undefined) || 
                        //     (cookieLanguage.toUpperCase() != $(settings.el.area).val().toUpperCase())) {
                            login.renewCaptcha(function(){
                                $.post(settings.data.site + settings.data.login, {
                                    'data' : {
                                        'area' : $(settings.el.area).val(),
                                        'page' : 'game',
                                        'gameCode': gameCode,
                                        'gameId': gameId,
                                        'gameLoginId': gameLoginId,
                                        'accessToken': cookieAccessToken,
                                        'platform' : cookiePlatform,
                                    },
                                    'g-recaptcha-response': $(settings.el.grecaptcha).val()
                                }, function(response){
                                    response = JSON.parse(response);
                                    if (login.isLogged(response)) {
                                        settings.data.isSignnedIn = true;
                                        login.loadJSGame();
                                        return true;
                                    } else {
                                        settings.data.isSignnedIn = false;

                                        let textError = 'Unknow error!';
                                        if (settings.dictionDefault['error_' + response.code] != undefined) {
                                            textError = settings.dictionDefault['error_' + response.code];
                                        } else {
                                            textError = 'Error: ' + response.code;
                                        }
                                        login.showError('signin', textError);
                                        
                                        login.cleanUpCookies();
                                        login.activePopup();
                                        return false;
                                    }
                                });
                            });
                        // } else {
                        //     settings.data.isSignnedIn = false;
                        //     login.activePopup();
                        //     return false;
                        // }


                    } else {
                        //login by popup
                        settings.data.isSignnedIn = false;
                        // login.cleanUpCookies();
                        login.activePopup();
                        return false;
                    }

                    break;

                case false: 

                    // home page

                    if ((cookieAccessToken != undefined)) { //  (cookiePlatform != undefined) &&  && (cookieAccessToken != undefined)
                        settings.data.isSignnedIn = true;
                        login.switchWelcome(cookieDisplayUsername);

                        login.renewCaptcha(function(){
                            $.post(settings.data.site + settings.data.login, {
                                'data' : {
                                    'area' : $(settings.el.area).val(),
                                    'page' : 'home',
                                    'accessToken': cookieAccessToken,
                                    'platform' : cookiePlatform,
                                },
                                'g-recaptcha-response': $(settings.el.grecaptcha).val()
                            }, function(response){
                                response = JSON.parse(response);
                                if (login.isLogged(response)) {
                                    settings.data.isSignnedIn = true;
                                    Cookies.set('resp_username', response.data.username);
                                    login.switchWelcome(response.data.username);
                                    return true;
                                } else {
                                    settings.data.isSignnedIn = false;

                                    let textError = 'Error: ' + response.code;
                                    if (settings.dictionDefault['error_' + response.code] != undefined) {
                                        textError = settings.dictionDefault['error_' + response.code];
                                    } else {
                                        textError = 'Error: ' + response.code;
                                    }
                                    login.showError('signin', textError);
                                    login.cleanUpCookies();
                                    login.activePopup();
                                    return false;
                                }
                            });
                        });

                        login.closePopup();

                        return true;
                    } else {
                        settings.data.isSignnedIn = false;

                        return false;
                    }


                default: 

                    break;
            }
        },
        registerRecentlyGame: function(dataGame){

            let langCode = Cookies.get('language');
            let recentlyGames = Cookies.get('recentlygames_' + langCode);  
            // console.log(recentlyGames);          
            if (recentlyGames != undefined) {
                recentlyGames = JSON.parse(recentlyGames);

                if (recentlyGames.length > 4) {
                    recentlyGames.pop();
                }
                recentlyGames.unshift(dataGame);

                Cookies.set('recentlygames_' + langCode, recentlyGames);
            } else {
                let tempRecentlyGames = [];
                tempRecentlyGames.unshift(dataGame); 

                Cookies.set('recentlygames_' + langCode, tempRecentlyGames);
            }

            return true;
        },
        showRecentlyGame: function(el = '#recentlyGames') {
            // expect dataGames = [{url, icon, label},...]

            let langCode = Cookies.get('language');
            let recentlyGames = Cookies.get('recentlygames_' + langCode);  
            // console.log(recentlyGames);

            if ($(el).length == 0) {
                return false;
            } else {
                if (recentlyGames != undefined) {
                    recentlyGames = JSON.parse(recentlyGames);
                    let domRecently = ``;
                    let urls = [];
                    for (let i = 0, len = recentlyGames.length; i < len; i++) {
                        let dataGame = recentlyGames[i];
                        let domGameSingle = `
                        <a class="game__icon play-now" href="${dataGame.url}">
                            <img class="" src="${dataGame.icon}" alt="${dataGame.label}">
                            <span class="label">${dataGame.label}</span>
                        </a>`;
                        let domGameSingleGrid = `
                        <div class="grid-game">
                            <a class="game__icon play-now" href="${dataGame.url}">
                                <img class="" src="${dataGame.icon}" alt="${dataGame.label}">
                                <span class="label">${dataGame.label}</span>
                            </a>
                        </div>`;
                        let isDuplicated = false;
                        for (let j = 0, lenj = urls.length; j < lenj; j++) {
                            if (urls[j] == dataGame.url) {
                                isDuplicated = true; 
                            }
                        }

                        if (!isDuplicated) {
                            urls.push(dataGame.url);

                            if ($("#listGameSide").hasClass("list-game")) {
                                domRecently += domGameSingleGrid;
                            } else {
                                domRecently += domGameSingle;
                            }
                        }
                    }

                    $(el).append(domRecently);

                    //
                    // remove duplicated 
                    //

                    $("#listGameSide").children(".play-now").each(function(){
                        let url = $(this).attr("href");

                        let isDuplicated = false;
                        for (let j = 0, lenj = urls.length; j < lenj; j++) {
                            if (urls[j] == url) {
                                isDuplicated = true;
                            }
                        }

                        if (!isDuplicated) {
                            urls.push(url);
                        } else {
                            $(this).css({
                                display: 'none'
                            }); 
                        }
                    });

                    $("#listGameSide").children(".grid-game").each(function(){

                        if (!$(this).hasClass("not-hide")) {
                            let url = $(this).children(".play-now").attr("href");
    
                            let isDuplicated = false;
                            for (let j = 0, lenj = urls.length; j < lenj; j++) {
                                if (urls[j] == url) {
                                    isDuplicated = true;
                                }
                            }
    
                            if (!isDuplicated) {
                                urls.push(url);
                            } else {
                                $(this).css({
                                    display: 'none'
                                }); 
                            }
                        }
                    });

                    return true;
                } else {
                    return false;
                }
            }
        },
        handle: {
            playNow: function(){
                $('body').on("click touchstart", settings.el.btnPlay, function(e){
                    e.preventDefault();
                    settings.data.gameLink = $(this).attr("href"); 

                    let icon = ($(this).children("img").attr("src") != undefined) ? $(this).children("img").attr("src") : $(this).children("img").attr("data-src")
                    login.registerRecentlyGame({
                        url: $(this).attr("href"),
                        icon: icon,
                        label: $(this).children("img").attr("alt")
                    });

                    if (settings.data.isSignnedIn) {
                        login.redirectTo(settings.data.gameLink);
                    } else {
                        login.cleanUpCookies();
                        login.activePopup();
                    }
                })
            },
            signinZingId: function(){
                $(settings.el.btnSigninZingID).on("click touchstart", function(){
                    login.renewCaptcha(function(){

                        let gameCode = '';
                        if ($("#gameCode").length > 0) {
                            gameCode = $("#gameCode").val();
                        }

                        // gameLoginId

                        let gameLoginId = '';

                        if ($("#gameLoginId").length > 0) {
                            gameLoginId = $("#gameLoginId").val();
                        }

                        // gameId

                        let gameId = '';

                        if ($("#gameId").length > 0) {
                            gameId = $("#gameId").val();
                        }

                        $.post(settings.data.site + settings.data.login, {
                            'data' : {
                                'username' : $(settings.el.inpUsername).val(),
                                'password' : $(settings.el.inpPassword).val(),
                                'area' : $(settings.el.area).val(),
                                'page' : $(settings.el.page).val(),
                                'gameId': gameId,
                                'gameLoginId': gameLoginId,
                                'gameCode' : gameCode,
                                'platform' : "ZP",
                            },
                            'g-recaptcha-response': $(settings.el.grecaptcha).val()
                        },function(response){
                            response = JSON.parse(response);
                            if (login.isLogged(response)) {
                                settings.data.isSignnedIn = true;
                                let username = $(settings.el.inpUsername).val();
                                Cookies.set('resp_username', username);
                                Cookies.set('zp_username', username);
                                login.switchWelcome(username);
                                login.closePopup();
                                if (settings.data.gameLink != "//") login.redirectTo(settings.data.gameLink);
                                if (login.isPlayingPage()) {
                                    login.loadJSGame();
                                };
                            } else {
                                let textError = 'Error: ' + response.code;
                                if (settings.dictionDefault['error_' + response.code] != undefined) {
                                    textError = settings.dictionDefault['error_' + response.code];
                                }
                                login.showError('signin', textError);
                            }
                        });
                    });
                });
            },
            signupZingId: function(){
                $(settings.el.btnSignupZingID).on("click touchstart", function(){

                    // console.log($(settings.el.regPassword).val(), $(settings.el.regRePassword).val(), settings.diction.notMatchPasswords)

                    if ($(settings.el.regPassword).val() != $(settings.el.regRePassword).val()) {
                        login.showError('signup', settings.diction.notMatchPasswords);
                    } else {
                        login.renewCaptcha(function(){
                            let gameCode = '';
                            if ($("#gameCode").length > 0) {
                                gameCode = $("#gameCode").val();
                            }
                            // gameLoginId
    
                            let gameLoginId = '';
    
                            if ($("#gameLoginId").length > 0) {
                                gameLoginId = $("#gameLoginId").val();
                            }
    
                            // gameId
    
                            let gameId = '';
    
                            if ($("#gameId").length > 0) {
                                gameId = $("#gameId").val();
                            }

                            $.post(settings.data.site + settings.data.register, {
                                'data' : {
                                    'username' : $(settings.el.regUsername).val(),
                                    'password' : $(settings.el.regPassword).val(),
                                    'area' : $(settings.el.area).val(),
                                    'page' : $(settings.el.page).val(),
                                    'gameId': gameId,
                                    'gameLoginId': gameLoginId,
                                    'gameCode' : gameCode,
                                    'platform' : "ZP",
                                },
                                'g-recaptcha-response': $(settings.el.grecaptcha).val()
                            },function(response){
                                response = JSON.parse(response);

                                if (response.code == 1) {
                                    let username = $(settings.el.regUsername).val();
                                    Cookies.set('resp_username', username);
                                    Cookies.set('zp_username', username);
                                    // login.redirectTo(settings.data.site);
                                    login.checkStatus();
                                    if (login.isPlayingPage()) {
                                        login.loadJSGame();
                                    };
                                } else {
                                    let textError = 'Error: ' + response.code;
                                    if (settings.dictionDefault['error_' + response.code] != undefined) {
                                        textError = settings.dictionDefault['error_' + response.code];
                                    }
                                    login.showError('signup', textError);
                                }
                            });
                        });
                    }


                });
            },
            signinFacebook: function(){
                $(settings.el.btnSigninFacebook).on("click touchstart", function(){
                    login.renewCaptcha(function(){
                        let gameCode = '';
                        if ($("#gameCode").length > 0) {
                            gameCode = $("#gameCode").val();
                        }
                        // gameLoginId

                        let gameLoginId = '';

                        if ($("#gameLoginId").length > 0) {
                            gameLoginId = $("#gameLoginId").val();
                        }

                        // gameId

                        let gameId = '';

                        if ($("#gameId").length > 0) {
                            gameId = $("#gameId").val();
                        }

                        FB.getLoginStatus(function(response) {
                            if (response.status === 'connected') {
    
                                FB.api('/me', function(apiResponse){
                                    $.post(settings.data.site + settings.data.login, {
                                        'data' : {
                                            'username': apiResponse.name,
                                            'accessToken' : response.authResponse.accessToken,
                                            'area' : $(settings.el.area).val(),
                                            'page' : $(settings.el.page).val(),
                                            'gameId': gameId,
                                            'gameLoginId': gameLoginId,
                                            'gameCode' : gameCode,
                                            'platform' : "FB",
                                        },
                                        'g-recaptcha-response': $(settings.el.grecaptcha).val()
                            
                                    },function (response) {
                                        response = JSON.parse(response);
                                        if (login.isLogged(response)) {
                                            settings.data.isSignnedIn = true;
                                            Cookies.set('resp_username', apiResponse.name);
                                            Cookies.set('zp_username', apiResponse.name);
                                            login.switchWelcome(apiResponse.name);
                                            login.closePopup();
                                            if (settings.data.gameLink != "//") login.redirectTo(settings.data.gameLink);
                                            if (login.isPlayingPage()) {
                                                login.loadJSGame();
                                            };
                                        }
                                    });
                                });
    
                            } else {
                                FB.login(function(response){
                                    if (response.authResponse) {
                                        FB.api('/me', function(apiResponse){
                                            $.post(settings.data.site + settings.data.login, {
                                                'data' : {
                                                    'username': apiResponse.name,
                                                    'accessToken' : response.authResponse.accessToken,
                                                    'area' : $(settings.el.area).val(),
                                                    'page' : $(settings.el.page).val(),
                                                    'gameId': gameId,
                                                    'gameLoginId': gameLoginId,
                                                    'gameCode' : gameCode,
                                                    'platform' : "FB",
                                                },
                                                'g-recaptcha-response': $(settings.el.grecaptcha).val()
                            
                                            },
                                            function(response) {
                                                response = $.parseJSON(response);
                                                if (login.isLogged(response)) {
                                                    settings.data.isSignnedIn = true;
                                                    Cookies.set('resp_username', apiResponse.name);
                                                    Cookies.set('zp_username', apiResponse.name);
                                                    login.switchWelcome(apiResponse.name);
                                                    login.closePopup();
                                                    if (settings.data.gameLink != "//") login.redirectTo(settings.data.gameLink);
                                                    if (login.isPlayingPage()) {
                                                        login.loadJSGame();
                                                    };
                                                }
                                            });
                                        })
                                    } else {
        
                                    }
                                })
                            }
                        });
                    });
                });
            },
            signinGoogle: function(){
                $(settings.el.btnSigninGoogle).on("click touchstart", function(){
                    $(".g-signin2").trigger("click");
                    // console.log("trigger ? ");
                });
            },
            signOut: function() {
                $(settings.el.btnSignout).on("click touchstart", function(){
                    Cookies.remove('zp_username');
                    Cookies.remove('zp_sessionKey'); 
                    Cookies.remove('zp_accessToken'); 
                    Cookies.remove('resp_username'); 

                    let zp_platform = Cookies.get('zp_platform');

                    Cookies.remove('zp_platform'); 
                    
                    let cookieSessionKeyName = Cookies.get('sessionKeyNameList');

                    if (cookieSessionKeyName != undefined) {
                        let sessionKeyName = JSON.parse(cookieSessionKeyName);

                        for (let i = 0, len = sessionKeyName.length; i < len; i++) {
                            Cookies.remove(sessionKeyName[i]);
                        }
                    }

                    if (zp_platform == 'GG') {
                        var auth2 = gapi.auth2.getAuthInstance();
                        auth2.disconnect();
                    }

                    Cookies.remove('sessionKeyNameList');

                    login.redirectTo(settings.data.site);
                });
            },
            enterInput: function(){
                $(settings.el.inpUsername).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSigninZingID).trigger("click");
                    }
                });
                $(settings.el.inpPassword).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSigninZingID).trigger("click");
                    }
                });
                $(settings.el.regUsername).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSignupZingID).trigger("click");
                    }
                });
                $(settings.el.regPassword).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSignupZingID).trigger("click");
                    }
                });
            },
            checkMatchPass: function(){
                $(settings.el.regPassword).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSignupZingID).trigger("click");
                    }
                });
                $(settings.el.regPassword).on("keydown", function(e){
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $(settings.el.btnSignupZingID).trigger("click");
                    }
                });
            },
            executeAll: function(){
                login.handle.playNow();
                login.handle.signinZingId();
                login.handle.signupZingId();
                login.handle.signinFacebook();
                login.handle.signinGoogle();
                login.handle.signOut();
                login.handle.enterInput();
            }
        }


    }

    login.checkStatus();
    login.showRecentlyGame();
    login.handle.executeAll();

    window.onSignIn = function(googleUser){
        let gameCode = '';

        if ($("#gameCode").length > 0) {
            gameCode = $("#gameCode").val();
        }
        
        // gameLoginId

        let gameLoginId = '';

        if ($("#gameLoginId").length > 0) {
            gameLoginId = $("#gameLoginId").val();
        }

        // gameId

        let gameId = '';

        if ($("#gameId").length > 0) {
            gameId = $("#gameId").val();
        }

        var profile = googleUser.getBasicProfile();

        // console.log(profile);

        googleUser.reloadAuthResponse().then((response) => {

            // console.log("GG resp: ", response);

            login.renewCaptcha(function(){
                $.post(settings.data.site + settings.data.login, {
                    'data' : {
                        'accessToken' : response.access_token,
                        'area' : $(settings.el.area).val(),
                        'page' : $(settings.el.page).val(),
                        'gameId': gameId,
                        'gameLoginId': gameLoginId,
                        'gameCode' : gameCode, 
                        'platform' : "GG",
                    },
                    'g-recaptcha-response': $(settings.el.grecaptcha).val()
        
                },function (response) {
                    response = JSON.parse(response);
                    // console.log(response);

                    response.data.username = profile.getName();
                    // Cookies.set('zp_username', response.data.username);  

                    // var auth2 = gapi.auth2.getAuthInstance();
                    // auth2.disconnect();

                    // console.log(response);

                    if (login.isLogged(response)) {
                        settings.data.isSignnedIn = true;
                        Cookies.set('resp_username', response.data.username);
                        Cookies.set('zp_username', response.data.username);
                        login.switchWelcome(response.data.username);
                        login.closePopup();
                        if (settings.data.gameLink != "//") login.redirectTo(settings.data.gameLink);
                        if (login.isPlayingPage()) {
                            login.loadJSGame();
                        };
                    }
                });
            })
        }).catch((error) => {
            console.log(error);
        });
    }
}