$.fn.tab = function(options){
    var defaults = {
      obj: $(this)
    }
    var settings = $.extend(defaults, options);

    let groupTab = settings.obj.data("tab");
    
    settings.obj.each(function(){
        $(this).on("click", function(){
            let tabContent = $(this).attr("href");
            $(this).parent().siblings().removeClass("active");
            $(this).parent().addClass("active");
            async function animate(){
                $(groupTab).removeClass("active");
                $(groupTab).fadeOut(200);
                $(tabContent).fadeIn(200);
            };
            animate();
        })
    })

  }
