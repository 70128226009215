 // SCROLLBAR

import Scrollbar from 'smooth-scrollbar';
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';


// LAZYLOAD

import 'lazysizes';
 
// CAROUSEL

import "animate.css";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import SwiperAnimation from '@cycjimmy/swiper-animation';

let swiperControl = {};
const swiperAnimation = new SwiperAnimation();

$(".swiper-container-heroGame").each(function(){
  let swiperSelector = $(this).data("swiper-selector");
  let swiperSettings = new Swiper(swiperSelector, {
    slidesPerView: 1,
    loop: true,
    // loopedSlides: 3,
    // effect: 'fade', 
    direction: 'vertical',
    lazy: {
      loadPrevNext: true
    }, 
    speed: 500
    // navigation: {
    //   nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
    //   prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
    // }
  })
  swiperControl[swiperSelector.substr(1)] = swiperSettings;
});
$(".swiper-container-heroContent").each(function(){
  let swiperSelector = $(this).data("swiper-selector");
  let swiperSettings = new Swiper(swiperSelector, {
    slidesPerView: 1,
    loop: true,
    direction: 'vertical',
    // loopedSlides: 3,
    // mousewheel: {
    //   sensitivity: 1,
    // },
    lazy: {
      loadPrevNext: true
    }, 
    speed: 500,
    // on: {
    //   init: function () {
    //     swiperAnimation.init(this).animate();
    //   },
    //   slideChange: function () {
    //     swiperAnimation.init(this).animate();
    //   }
    // },
    on: {
      slideChange: function(){
        // console.log(this);
        let slidePage = $(".swiper-container-heroPagination .swiper-slide");
        let slideLength = slidePage.length;
        let activeIndex = (this.activeIndex % slideLength) - 1;
        // console.log(activeIndex);
        if (slideLength > 0) {
          slidePage.removeClass("active");
          slidePage.eq(activeIndex).addClass("active");
        }

        console.log(activeIndex);

        let wrapperPage = $(".swiper-container-heroPagination .swiper-wrapper");

      }
    },
    // pagination: {
    //   el: '.swiper-pagination-' + swiperSelector.substr(1),
    //   clickable: true,
    //   dynamicBullets: true,
    //   renderBullet: function(index, className){
    //     let thumbnailUrl = $(swiperSelector).children(".swiper-wrapper").children(".swiper-slide").eq(index).data("swiper-thumbnail");
    //     let labelTxt = $(swiperSelector).children(".swiper-wrapper").children(".swiper-slide").eq(index).data("swiper-label");
    //     return `
    //       <div class="${className}">
    //         <div class="game__icon game__icon--sub">
    //           <img class="lazyload" data-src="${thumbnailUrl}" alt="${labelTxt}">
    //           <span class="label">${labelTxt}</span>
    //         </div>
    //       </div>
    //     `;
    //   }
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
    //   prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
    // } 
  })
  swiperControl[swiperSelector.substr(1)] = swiperSettings;

  swiperControl["heroContent"].controller.control = [swiperControl["heroGame"]]; 

});
$(".swiper-container-heroPagination").each(function(){
  let swiperSelector = $(this).data("swiper-selector");
  let swiperSettings = new Swiper(swiperSelector, {
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
      prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
    },
    lazy: true,
    on: {
      click: function(){
        let clickedIndex = this.clickedIndex;
        // swiperControl["heroContent"].slideTo(clickedIndex + 1);
        swiperControl["heroCarousel"].slideTo(clickedIndex + 1);
        $(this.clickedSlide).siblings().removeClass("active");
        $(this.clickedSlide).addClass("active");
      }
    },
    breakpoints: {
      600: {
        slidesPerView: 5
      }
    }
    // freeMode: true,
    // centeredSlides: true, 
    // centeredSlidesBounds: true,
    // touchRatio: 1,
    // allowTouchMove: false,
    // slideToClickedSlide: true
  })
  swiperControl[swiperSelector.substr(1)] = swiperSettings;

  // swiperControl["heroContent"].controller.control = [swiperControl["heroGame"], swiperControl["heroPagination"]];
  // swiperControl["heroPagination"].controller.control = [swiperControl["heroGame"], swiperControl["heroContent"]];
}); 


$(".swiper-container-heroCarousel").each(function(){
  let swiperSelector = $(this).data("swiper-selector");
  let swiperSettings = new Swiper(swiperSelector, {
    slidesPerView: 1,
    loop: true,
    direction: 'vertical',
    // loopedSlides: 3,
    // mousewheel: {
    //   sensitivity: false, 
    //   releaseOnEdges: true, 
    // }, 
    lazy: {
      loadPrevNext: true
    }, 
    speed: 500,
    on: {
      slideChange: function(){

        // console.log('a');

        // setTimeout(function () {
        //   swiperControl[swiperSelector.substr(1)].params.mousewheel.releaseOnEdges = false;
        // }, 500);
        // console.log(this);
        let slidePage = $(".swiper-container-heroPagination .swiper-slide");
        let slideLength = slidePage.length;
        // console.log(this.activeIndex);
        // console.log(slideLength);
        
        // let activeIndex = (this.activeIndex % slideLength) - 1;
        let activeIndex = (this.activeIndex == 0 || this.activeIndex == slideLength) ?  slideLength - 1: (this.activeIndex % slideLength) - 1;
        // console.log(activeIndex);
        if (slideLength > 0) {
          slidePage.removeClass("active");
          slidePage.eq(activeIndex).addClass("active");
        }
        // console.log(activeIndex);

        let wrapperPage = $(".swiper-container-heroPagination .swiper-wrapper");
        const translate_unit = -140;
        const translate_max_multiplier = slideLength - 5;
        let translate_x = 0;

        if (activeIndex < 3) {
          translate_x = translate_unit * 0;
        } else if (activeIndex + 4 > slideLength) {
          translate_x = translate_unit * translate_max_multiplier;
        } else {
          translate_x = translate_unit * (activeIndex - 2);
        }

        wrapperPage.css({
          transition: 'all .2s',
          transform: 'translate3d(' + translate_x + 'px, 0, 0)'
        });


        

      },
      reachEnd: function() {
          // setTimeout(function () {
          //   swiperControl[swiperSelector.substr(1)].params.mousewheel.releaseOnEdges = true;
          // }, 750);
      }
    },
  })
  swiperControl[swiperSelector.substr(1)] = swiperSettings;
});


// mouse wheel 

import "jquery-mousewheel";

$("body").on("mousewheel", function(e){
  // console.log(e.deltaY);

  if (e.deltaY < 0) {
    swiperControl["heroCarousel"].slideNext();
  } else {
    swiperControl["heroCarousel"].slidePrev();
  }



})


// SELECT

import "select2";
import "select2/dist/css/select2.css";

$('.form-control > select').each(function(){
  $(this).select2({
    minimumResultsForSearch: Infinity
  }); 
});

// DATERANGE

import "daterangepicker";
import "daterangepicker/daterangepicker.css";

$('.form-control > [data-singledate]').each(function(){
  $(this).daterangepicker({
    "singleDatePicker": true,
    "showDropdowns": true,
    "startDate": "03/28/2020",
    "endDate": "04/03/2020",
    "opens": "center",
    "drops": "up"
  }); 
});