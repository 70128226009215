
// 

import "./components/_toggleClass";
// import "./components/_fetchDuration";
import "./components/_lightbox";
// import "./components/_tournament"; 
// import "./components/_imageInput";
import "./components/_social";
// import "./components/_embed";
import "./components/_activeNav";
import "./components/_loadAjax";
// import "./components/_fixedNav";
import "./components/_scrollLink";
import "./components/_accordion";
import "./components/_tab";
import "./components/_nav-metro.js";
import "./components/_login.js";
import "./components/_language.js";
import initIcons from "./components/_svg-icons.js";

$(function(){

	initIcons();
    
    $(".burger").each(function(){
        $(this).toggleClass({
            toggle: [{
                el: $("#navigationDetail"),
                className: 'active'
            }],
            before: function(){
            },  
            after: function(){
                if ($("#navigationDetail").hasClass("active")) {
                    $("body").addClass("popup-opened");
                } else {
                    $("body").removeClass("popup-opened");
                } 
            }
        }) 
    });

    $(".view-all-btn").each(function(){
        $(this).on("click", function(){
            $(this).parent().next().children().removeClass("hide");
            $(this).hide();
        })
    })

    $("[data-scrolllink]").each(function(){
        let scrollLink_offset = 0;
        if ($(this).data("scrolllink") != 0) {
            scrollLink_offset = $(this).data("scrolllink");
        }        
        $(this).scrollLink({
            offset: scrollLink_offset 
        });
    })

    $(".accordion").each(function(){
        $(this).accordion();
    })


    $("[data-tab]").each(function(){
        $(this).tab();
    })


    $("[data-youtube-fetch-duration]").each(function(){
        $(this).fetchDuration(); 
    }) 

    $("#navigationDetail").each(function(){
        let headerHeight = $("#header").outerHeight();
        $(this).fixedNav({
            initHeight: headerHeight
        });
    });
    
    // 
    
    if ($("[data-lightbox]").length > 0) {
        $("[data-lightbox]").each(function(){
            $(this).lightBox({
                objLightBox: $(this).attr('href'),
                type: $(this).data('lightbox-type')
            });
        }); 
    }
    
    //
    
     
    
    if ($('input[type="file"]').length > 0) {
        $('input[type="file"]').imageInput();
    }
    
    //
    
    
    $("[data-callsocial]").each(function(){
        $(this).callSocial();
    })
    
    // 
    
    
    $("[data-embed]").each(function(){
        $(this).embed({
            objToEmbed: $(this).children(".video__content")
        });
    }) 
    
    if ($(".page").length > 0) {
        $(".page").eq(0).activeNav({
            objToActive: "nav"
        }); 
    }
    
    $("[data-loadajax-core]").each(function(){
        $(this).loadAjax({
            language: {
                'loading': 'Đang tải nội dung...',
                'error': 'Có lỗi xảy ra!',
                'noresult': 'Không có kết quả!'
            }
        });
    });

    $("#listGame").each(function(){
        $(this).toggleClass({
            toggle: [
                {el: $(".list-game-side"), className: 'active'},
                {el: $(this), className: 'is-active'}
            ],
            after: function(){
                var idleTimer = 0;
                var idleTrigger = false;

                $(".list-game-side").mouseover((e) => {
                    idleTimer = 0;
                })
            
                setInterval(function(){
                    idleTimer += 1;
                    if (idleTimer > 5 && !idleTrigger) {
                        $(".list-game-side").removeClass("active");
                        $("#listGame").removeClass("is-active");
                        idleTrigger = true;
                    }
                }, 1000);
            }
        });

        var idleTrigger = false;
        var idleTimer = 0;
        setInterval(function(){
            idleTimer += 1;
            if (idleTimer > 5 && !idleTrigger) {
                $(".list-game-side").removeClass("active");
                $("#listGame").removeClass("is-active");
                idleTrigger = true;
            }
        }, 1000);
    });

    

    $("#navZingplay").each(function(){
        $(this).toggleClass({
            toggle: [
                {el: $(".navigation--zingplay"), className: 'active'},
                {el: $(this), className: 'is-active'}
            ]
        })
    });

    $("[data-nav-metro]").each(function(){
        $(this).navMetro({
            objToggle: $($(this).data('nav-metro')),
            before: function(){
                $(".navigation--zingplay").removeClass("active");
                $("#navZingplay").removeClass("is-active");
            }
        })
    });

    if (typeof site !== 'undefined') {
        $().login({
            data: {
                site: site,
                login: '/login-zp/home-login.html', 
                register: '/register-zp/home-login.html',
                grecaptchaKey: '6LfDrLsUAAAAALSewo3hFNU8T9nGYFrwhjafVPLu',
                facebookAppId: '',
                isSignnedIn: false,
                gameLink: '//'
            },
            diction: {
                wrongAuth: $("#wrongAuth").val(),
                notAvailUsername: $("#notAvailUsername").val(),
                notMatchPasswords: $("#notMatchPasswords").val(),
                wrongReg: $("#wrongReg").val(),
            },
            dictionDefault: {
                error_n1: $("#error_n1").val(),
                error_1: $("#error_1").val(),
                error_4: $("#error_4").val(),
                error_5: $("#error_5").val(),
                error_6: $("#error_6").val(),
                error_7: $("#error_7").val(),
                error_8: $("#error_8").val(),
                error_10: $("#error_10").val(),
                error_11: $("#error_11").val(),
                error_12: $("#error_12").val(),
                error_13: $("#error_13").val(),
                error_14: $("#error_14").val(),
                error_15: $("#error_15").val(),
                error_16: $("#error_16").val(),
                error_17: $("#error_17").val(),
                error_18: $("#error_18").val(),
                error_20: $("#error_20").val(),
                error_30: $("#error_30").val(),
                error_101: $("#error_101").val(),
                error_102: $("#error_102").val(),
                error_121: $("#error_121").val()
            }
        });
    }

    $().language();

    $("#signIn").each(function(){
        $(this).children(".button").on("click", function(e){
            e.preventDefault();
            $("#tabSignin").trigger("click");
            $("body").addClass("popup-opened");
            $($(this).attr("href")).addClass('active');
        })
    });

    $("#signUp").each(function(){
        $(this).children(".button").on("click", function(e){
            e.preventDefault();
            $("#tabSignup").trigger("click");
            $("body").addClass("popup-opened");
            $($(this).attr("href")).addClass('active');
        })
    });

});
