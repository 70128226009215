// INITIALIZE

var icons = [];

// PUSH

icons.push("common/close", "common/chevron", "common/arrow", "common/play", "common/download", "common/upload");
icons.push("lang/vn", "lang/th", "lang/mm", "lang/en", "lang/ph", "lang/mx", "lang/br");
icons.push("platform/desktop", "platform/mobile");
icons.push("download/appstore", "download/googleplay");
icons.push("common/contact", "social/facebook-f", "common/fullscreen", "common/minimize");
icons.push("social/facebook-messenger", "social/facebook-share");
 
const initIcons = function() {
	icons.forEach(function(v) { 
		$('.icon[data-icon="'+v+'"]').html(require('../../icons/'+v+'.svg'));
	});
}


export default initIcons;