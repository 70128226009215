$.fn.accordion = function(options){
    var defaults = {
      obj: $(this)
    }
    var settings = $.extend(defaults, options);
    
    settings.obj.children(".accordion__set").children(".accordion__title").each(function(){
        $(this).on("click", function(){
            console.log("a")
            if ($(this).hasClass("active")){
                $(this).removeClass("active");
                $(this).next(".accordion__content").slideUp(200);
            } else {
                settings.obj.children(".accordion__set").children(".accordion__title").removeClass("active");
                settings.obj.children(".accordion__set").children(".accordion__content").slideUp(200);
                $(this).addClass("active");
                $(this).next(".accordion__content").slideDown(200);
            }
        })
    })

  }
