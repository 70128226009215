$.fn.navMetro = function(options){
    var defaults = {
      obj: $(this),
      classToggle: 'active',
      objToggle: $(this).parent(),
      animate: {}, 
      animateToggle: {},
      before: () => {},
      after: () => {}
    }
    var settings = $.extend(defaults, options);

    settings.obj.on("mouseenter touchstart", (e) => {
        settings.before();
        settings.objToggle.addClass(settings.classToggle);
        settings.after();
    });
    settings.obj.on("mouseleave", (e) => {
        // e.preventDefault();
        settings.before();
        settings.objToggle.removeClass(settings.classToggle);
        settings.after();
    });

    // // Close (Mobile)

    // const isNotClickTarget = function(e){
    //     console.log($(e.target));
    //     console.log(settings.objToggle.has(e.target).length + $(".navigation").has(e.target).length);
        
    //     return (settings.objToggle.has(e.target).length + $(".navigation").has(e.target).length > 0);
    //     // console.log(settings.obj);
    //     // console.log(settings.objToggle);
    //     // console.log('...');
    // }

    // $(document).on("click", function(e) {
    //     if (isNotClickTarget(e)) {
    //         settings.objToggle.removeClass(settings.classToggle);
    //     } else {
    //         settings.objToggle.addClass(settings.classToggle);
    //     }
    // })

}